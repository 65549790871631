import Center from 'components/aboutUs/center';
import Core from 'components/aboutUs/core';
import Our from 'components/aboutUs/Our';
import Banner from 'components/banner';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { navigate } from 'gatsby';
import React from 'react';

export default function About() {
  const onHandleClick = () => {
    navigate('/contact/', { state: { name: 'contact' } });
  };
  return (
    <Layout>
      <SEO title="About" />
      <Banner
        height={'400px'}
        title={'We are Skyline Tech'}
        content={' There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour '}
      />
      <div className="container xl:px-[50px] mx-auto">
        <Center />
        <Core />
        <Our />
      </div>
    </Layout>
  );
}
