import React from 'react';
import pind from '../../images/pind.png';
import next from '../../images/next.png';
import Rectangle from '../../images/Rectangle.png';

export default function Core() {
  return (
    <div className="mt-20">
      <div className="flex  flex-col ">
        <h2 className=" font-bold text-3xl text-[#2A2F36]  md:w-100 mx-auto md:mx-0">Core Values</h2>
        <img className="mt-4 w-[175px] mx-auto md:mx-0" src={pind} />
      </div>
      <div className="flex justify-center md:justify-start flex-wrap ">
        <div className="max-w-[650px] sm:mr-[40px]">
          <div className="mt-8 sm:mt-4 flex mr-6">
            <img className="mr-2 w-[45px] h-[45px]" src={next} />
            <h2 className="font-bold text-xl mt-2 text-[#2A2F36] ">Winner’s mindset, not loser’s</h2>
          </div>
          <p>
            New challenges are met head on with bravery and determination. No one at SkylineTech hesitates, fears failure or is superficial when assigned a task. At
            SkylineTech, we always ask ourselves when being assigned any task: “What do I want to achieve and how that will help the company grow?” There should never be
            an attitude like “I only need to finish my given tasks because someone will take care of everything else.“
          </p>
          <div className="mt-8 sm:mt-4 flex mr-6">
            <img className="mr-2 w-[45px] h-[45px]" src={next} />
            <h2 className="font-bold text-xl mt-2 text-[#2A2F36] ">Beginner's mindset, not expert's</h2>
          </div>
          <p>
            In the current fast-changing world of technology, what we seem to "know" today can quickly become outdated by tomorrow. It is important for SkylineTech's
            members not to wear hats as experts because it will only lead them down a path.
          </p>
        </div>
        <div className="max-w-[650px] sm:mr-[40px] ">
          <img className="" src={Rectangle} />
        </div>
      </div>
    </div>
  );
}
