import React from 'react';
import Skylineperson from '../../images/Skylineperson.png';
import { navigate } from 'gatsby';
import pind from '../../images/pind.png';

export default function Center() {
  const onHandleClick = () => {
    navigate('/contact/', { state: { name: 'contact' } });
  };
  return (
    <div className=" mt-[55px]">
      <div className="flex justify-between flex-wrap md:flex-nowrap ">
        <div className="  w-[650px] flex flex-col md:block ">
          <h2 className="font-bold text-3xl text-[#2A2F36] mt-10 mx-auto md:mx-0 ">We are Skyline Tech</h2>
          <img className="mt-4 w-[175px] mx-auto md:mx-0" src={pind} />
          <p className="mt-6 text-[#5C5C5C]">
            Established in 2020, SkylineTech is one of the first Vietnamese startups that has been successful in developing open-source products for hundred thousand
            customers worldwide. The company houses talented young people who hold a strong passion for coding, designing, and marketing. All of them share the same dream
            of building and bringing innovative products to customers all over the world.
          </p>
          <p className="mt-6 text-[#5C5C5C] ">
            What continually motivates us is the strong passion for work, ever-changing technologies, and the high demand for perfection from the customer community.
          </p>
          <h2 className="font-bold text-xl mt-4 text-cyan-400 ">“Skyline to be remarkable!”</h2>
          <button
            onClick={onHandleClick}
            className=" bg-gradient-to-r from-cyan-500 to-teal-500  text-white font-bold py-2 px-6 rounded-full mt-6 h-[48px] w-[175px] mx-auto md:mx-0 ">
            Join Skyline Tech
          </button>
        </div>
        <div className="mt-[50px] md:mt-0 ">
          <img src={Skylineperson} />
        </div>
      </div>
    </div>
  );
}
