import React from 'react';
import pind from '../../images/pind.png';
import imagetwo from '../../images/imagetwo.png';

export default function Our() {
  return (
    <div className="md:py-10">
      <div className="flex  flex-wrap">
        <div className="w-[640px] md:mr-[40px] mt-20 flex flex-col ">
          <h2 className="font-bold text-3xl text-[#2A2F36] mx-auto md:mx-0">Our mission</h2>
          <img className="mt-2 w-[175px] mx-auto md:mx-0" src={pind} />
          <p className="mt-4 text-[#5C5C5C] ">
            To become the lever for Vietnamese people, enabling them to unleash their potential. This goal may be hindered by objective realities but with opportunities
            offered in a flat world we now have an opportunity in which our talent can shine.
          </p>
          <img className="mt-4" src={imagetwo} />
        </div>
        <div className=" w-[640px] md:my-20  flex flex-col  ">
          <h2 className="font-bold text-3xl text-[#2A2F36] mx-auto md:mx-0">Working at Skyline Tech</h2>
          <img className="mt-2 w-[175px] mx-auto md:mx-0" src={pind} />

          <h2 className="font-bold text-base mt-4 ">Attractive salary & bonus</h2>
          <p className="mt-4 text-[#5C5C5C] ">
            We know that a good investment starts with respecting and rewarding your employees for any contributions or achievements they might have made.
          </p>
          <h2 className="font-bold text-base mt-4">Room to grow</h2>
          <p className="mt-4 text-[#5C5C5C]">
            The way we strive for excellence creates limitless room to grow for every individual. There will always be a challenge for you to overcome and improve
            yourself.
          </p>
        </div>
      </div>
    </div>
  );
}
